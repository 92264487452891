<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h5 class="font-weight-light">LIST OF DISTRICT/CLUBS</h5>
        </v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <span class="font-weight-light white--text"
        >
                                                Total People:{{total_pep}}
        | Total Regular:{{total_reg}} | Total Beneficiary:{{total_ben}}
        | Total Scholar:{{total_sch}} | Total Volunteer:{{total_vol}}
                                            </span>
      </v-toolbar>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-uppercase">
              Date Started
            </th>
            <th class="text-uppercase">
              Registered District/Club
            </th>
            <th class="text-center text-uppercase">
              Number of People
            </th>
            <th class="text-center text-uppercase">
              Number of Regular
            </th>
            <th class="text-center text-uppercase">
              Number of Beneficiary
            </th>
            <th class="text-center text-uppercase">
              Number of Scholar
            </th>
            <th class="text-center text-uppercase">
              Number of Volunteer
            </th>
            <th class="text-center text-uppercase">
              Category
            </th>
            <th class="text-center text-uppercase">
              Status
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in district_list"
            :key="item.id"
          >
            <td>{{ item.date_established }}</td>
            <td>
              {{ item.district_name }}
            </td>
            <td class="text-center">{{ item.officers.length }}</td>
            <td class="text-center">
              <v-chip @click="open_details(item.regular_officers,'REGULAR',item.district_name )"
                      v-if="item.regular_officers.length>0">
                {{
                item.regular_officers.length>0?item.regular_officers.length:''
                }}
              </v-chip>
            </td>
            <td class="text-center">
              <v-chip
                @click="open_details(item.beneficiary_officers,'BENEFICIARY',item.district_name )"
                v-if="item.beneficiary_officers.length>0">
                {{
                item.beneficiary_officers.length>0?item.beneficiary_officers.length:'' }}
              </v-chip>
            </td>
            <td class="text-center">
              <v-chip @click="open_details(item.scholar_officers,'SCHOLAR',item.district_name )"
                      v-if="item.scholar_officers.length>0">
                {{
                item.scholar_officers.length>0?item.scholar_officers.length:''
                }}
              </v-chip>
            </td>
            <td class="text-center">
              <v-chip @click="open_details(item.volunteer_officers,'VOLUNTEER',item.district_name )"
                      v-if="item.volunteer_officers.length>0">
                {{
                item.volunteer_officers.length>0?item.volunteer_officers.length:'' }}
              </v-chip>
            </td>
            <td class="text-center">
              <div v-if="item.is_club===1">
                <v-chip color="warning" dark>CLUB</v-chip>
              </div>
              <div v-else-if="item.is_club===0">
                <v-chip color="info" dark>DISTRICT</v-chip>
              </div>
            </td>
            <td class="text-center">
              <div v-if="item.is_active===1">
                <v-chip color="error" dark>DEACTIVATED</v-chip>
              </div>
              <div v-else-if="item.is_active===0">
                <v-chip color="success" dark>ACTIVE</v-chip>
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog v-model="officers_dialog" persistent max-width="80%">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">LIST OF {{details}} IN
            {{name_of_district}} </h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="details_data"
          loading-text="Loading... Please wait"
        >
          <template v-slot:item.change_type="{ item }">
            <v-btn outlined
                   class="w-full"
                   color="warning"
                   @click="change_type(item)"
                   v-if="position==='ADMIN'"
            >
              Change Type of Member
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
      <v-btn color="error" @click="officers_dialog = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="is_change_type_member" persistent max-width="80%">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">CHANGE TYPE OF MEMBER of
          {{selected_to_edit}}</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-row>
          <v-col
            md="12"
            cols="12"
          >
            <v-select
              class="mx-2 mt-2"
              v-model="type_of_member"
              dense
              outlined
              label="Type of Member"
              :items="['Beneficiary','Regular','Volunteer','Scholar']"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn color="primary" @click="save_change_type"> Save Changes</v-btn>
          <v-btn color="error" @click="is_change_type_member = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {mdiSquareEditOutline, mdiDotsVertical} from '@mdi/js'
  import {mapActions, mapGetters} from "vuex";

  export default {
    data() {
      return {
        officers_dialog: false,
        is_change_type_member: false,
        details: '',
        name_of_district: '',
        type_of_member: '',
        selected_to_edit: '',
        selected_member: {},
        total_pep: 0,
        total_reg: 0,
        total_ben: 0,
        total_sch: 0,
        total_vol: 0,
        district_list: [],
        details_data: [],
        headers: [
          {text: 'Position', value: 'position'},
          {text: 'Last Name', value: 'members.last_name'},
          {text: 'First Name', value: 'members.first_name'},
          {text: 'Effective Date', value: 'date_started'},
          {text: 'Action', value: 'change_type'},
        ],
        // icons
        icons: {
          mdiSquareEditOutline,
          mdiDotsVertical,
        },
      }
    },
    mounted() {
      this.list_of_clubs_and_district()
      this.auto_renew_due_members()
    },
    computed: {
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['position']),
      ...mapGetters('form_rules', ['rules']),
    },
    methods: {
      ...mapActions('district', ['list_of_district_dashboard']),
      ...mapActions('members_information', ['auto_renew_due_members', 'change_type_of_member']),
      ...mapActions('system_data', ['change_snackbar']),
      list_of_clubs_and_district() {
        this.list_of_district_dashboard()
          .then(response => {
            this.district_list = response.data
            var tppptt = 0
            var tppptt2 = 0
            var tppptt3 = 0
            var tppptt4 = 0
            var tppptt5 = 0
            this.district_list.forEach(function (item) {
              tppptt += item.officers.length
              tppptt2 += item.regular_officers.length
              tppptt3 += item.beneficiary_officers.length
              tppptt4 += item.scholar_officers.length
              tppptt5 += item.volunteer_officers.length
            });
            this.total_pep = tppptt
            this.total_reg = tppptt2
            this.total_ben = tppptt3
            this.total_sch = tppptt4
            this.total_vol = tppptt5
          })
      },
      open_details(items, details, district_name) {
        if (this.position === 'ADMIN' || this.position === 'ADMIN SPECIAL') {
          this.name_of_district = district_name
          this.details = details
          this.officers_dialog = true
          this.details_data = items
        }
      },
      change_type(item) {
        this.selected_member = item
        this.is_change_type_member = true
        this.type_of_member = item.members.type_of_member
        this.selected_to_edit = item.members.last_name + ', ' + item.members.first_name + ' ' + item.members.middle_name
      },
      save_change_type() {
        this.change_type_of_member({
          member_id: this.selected_member.member_id,
          type_of_member: this.type_of_member,
        }).then(response => {
          var color = 'success'
          if (response.status === 201) {
            color = 'error'
          }
          this.change_snackbar({
            show: true,
            color: color,
            text: response.data,
          })
          this.is_change_type_member = false
          this.officers_dialog = false
          this.list_of_clubs_and_district()
        })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
          })
      },
    }
  }
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12">
        <dashboard-datatable v-if="position!='USER' && position!='MERCHANT PARTNER'"></dashboard-datatable>
        <SearchMembers v-if="position==='USER'"></SearchMembers>
        <ListOfPaidMembers v-if="position==='MERCHANT PARTNER'"></ListOfPaidMembers>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  // eslint-disable-next-line object-curly-newline
  import {mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline} from '@mdi/js'

  // demos
  import DashboardDatatable from './DashboardDistrictOrClub.vue'
  import SearchMembers from '../search_interface/SearchMembers'
  import ListOfPaidMembers from '../reports_interface/ListOfPaidMembers'
  import {mapGetters} from "vuex";


  export default {
    components: {
      SearchMembers,
      ListOfPaidMembers,
      DashboardDatatable,
    },

    computed: {
      ...mapGetters('authentication', ['position']),
    },
    setup() {
      const totalProfit = {
        statTitle: 'Total Profit',
        icon: mdiPoll,
        color: 'success',
        subtitle: 'Weekly Project',
        statistics: '$25.6k',
        change: '+42%',
      }

      const totalSales = {
        statTitle: 'Refunds',
        icon: mdiCurrencyUsd,
        color: 'secondary',
        subtitle: 'Past Month',
        statistics: '$78',
        change: '-15%',
      }

      // vertical card options
      const newProject = {
        statTitle: 'New Project',
        icon: mdiLabelVariantOutline,
        color: 'primary',
        subtitle: 'Yearly Project',
        statistics: '862',
        change: '-18%',
      }

      const salesQueries = {
        statTitle: 'Sales Quries',
        icon: mdiHelpCircleOutline,
        color: 'warning',
        subtitle: 'Last week',
        statistics: '15',
        change: '-18%',
      }

      return {
        totalProfit,
        totalSales,
        newProject,
        salesQueries,
      }
    },
  }
</script>
